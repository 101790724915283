<script>
export default {
  metaInfo: {
    title: 'Стоимость домашней бухгалтерии ХаниМани',
    meta: [
      { name: 'description', content: 'Сколько стоит система ХаниМани' }
    ]
  },
  components: {
    hm_slideshow: require('@/components/hm_slideshow.vue').default
  }
}
</script>

<template lang="pug">
.pricing-page
  section.hero.is-info(style='background-color: purple;')
    .hero-body
      .container.content
        .title.is-2 Сколько стоит решение финансовых проблем и уверенность в завтрашнем дне?
  section.section
    .container.content.has-text-centered
      .title.is-3 ХаниМани стоит гораздо меньше!
      .subtitle.is-5 +30 дней бесплатного доступа для пробы
      .columns
        .column.is-10.is-offset-1
          .box
            .columns.price_table
              .column
                .subtitle.is-3 3 месяца
                img(src='./assets/pricing/3.png')
                .subtitle.is-4 $5 / мес
                //- .subtitle.is-6 $15
              .column
                .subtitle.is-3 6 месяцев
                img(src='./assets/pricing/6.png')
                .subtitle.is-4 $4 / мес
                //- .subtitle.is-6 $24
              .column
                .subtitle.is-3: strong 1 год
                img(src='./assets/pricing/12.png')
                .subtitle.is-4: strong.has-text-success $3 / мес
                //- .subtitle.is-6 $36
              .column
                .subtitle.is-3 2 года
                img(src='./assets/pricing/24.png')
                .subtitle.is-4 $2 / мес
                //- .subtitle.is-6 $48
            .has-text-centered(style='color: rgb(142, 142, 142);') Поесть вреднючки в макдональдсе стоит дороже месяца полезного ХаниМани! :)

  .has-text-centered
    p: strong Тяжёлая финансовая ситуация?
    p Просто <a href='mailto:bee@hmbee.ru'>напишите мне письмо</a> и я сделаю вам доступ бесплатно.
    p ХаниМани ведь для того и существует, чтобы денег всегда хватало на всё.

  section.section
    .columns.is-centered
      .column.is-8
        .message.is-danger: .message-body.has-text-left
          .columns.is-vcentered.is-gapless
            .column
              .is-size-4 «Э-э-э-э, а чего это оно платное-то?!?»
            .column.is-narrow.has-text-centered
              div: img(src='@/assets/bees/skeptical1.png', style='height: 120px;')
              i пчёл-скептик
        .message.is-warning: .message-body.has-text-left
          .columns.is-vcentered
            .column.is-narrow.has-text-centered
              hm_slideshow(style="width: 100px; height: 126px; display: inline-block")
                div: img(src='@/assets/me1.png', style='width: 100px;')
                div(style='display: none'): img(src='@/assets/me2.png', style='width: 100px;')
            .column
              p
                | Платный доступ — единственный способ, которым ХаниМани зарабатывает деньги. Здесь совсем нет рекламы и ваши данные никогда не продаются никаким «партнёрам».
                | Доходы идут на поддержание работы сервиса и на поддержание жизни меня, его единственного создателя.
              p: i
                | Ильдар Абдулин, пчёл-основатель 👋

  section.section
    .container.content.has-text-centered
      .columns
        .column.is-10.is-offset-1
          h1.has-text-success Делаю и буду делать:
          .message.is-success
            .message-body
              .doing
                h3.has-text-success Я постоянно развиваю и улучшаю систему
                h5 Идей очень много. Система будет только хорошеть
                h3.has-text-success Я обеспечиваю стабильную работу сервиса
                h5
                  strong 24х7х365
                  br
                  | ХаниМани — прибыльный проект. Так что он никуда не пропадёт и не отключится
                  br
                  | (работает с 2011 года!)
                h3.has-text-success Я всячески поддерживаю пользователей
                h5
                  | Лично отвечаю на ВСЕ письма,&nbsp;
                  a(href='mailto:bee@hmbee.ru') попробуйте сами
          h1.has-text-danger Никогда не делал и не буду:
          .message.is-danger
            .message-body
              .not_doing
                h3.has-text-danger НЕ продаю базу пользователей «налево»
                h5 Это низко
                h3.has-text-danger НЕ показываю рекламу
                h5 Платный доступ — это по-честному
                h3.has-text-danger НЕ впариваю скрытно услуги «партнёров»
                h5 Это неуважение к пользователям
          h1.has-text-info Что получаете вы:
          .message.is-info
            .message-body
              .you_get
                h3.has-text-info Систему планирования и учёта финансов
                h5 И вы действительно будете ей пользоваться
                h3.has-text-info Полезные финансовые привычки на всю жизнь
                h5 Учёт, планирование, сбережение
                h3.has-text-info Удовольствие от пользования системой
                h5 Сделанное с душой и любовью всегда чувствуется
                h3.has-text-info Уверенность, спокойствие, надёжность
                h5 Деньги — слишком важны, чтобы не держать их под контролем
          .testimonial-container.left.testimonial-green
            blockquote.testimonial
              p
                | Пользуюсь системой с декабря 2011 года. Система очень удобная, интуитивно понятная и
                a(href='https://app.hmbee.ru/app/changelog', target='_blank')  с каждым днем все лучше и лучше
                | !
              p
                | Ильдар действительно любит своё детище и постоянно её развивает. Наверное, ни один человек «за зарплату» никогда бы не смог такого сделать, тут нужна настоящая
                strong  СТРАСТЬ
                | . Всем своим знакомым рекомендую ХаниМани, она очень простая и удобная, и при этом еще и имеет отличный дизайн!
            .testimonial-arrow-down
            p.testimonial-author
              | Дмитрий
              span  (почти 2 года с ХаниМани)
          .testimonial-container.right.testimonial-yellow
            blockquote.testimonial
              p
                | ХаниМани стала первой системой для ведения и планирования домашнего учета,
                strong  которую я не забросил спустя пару месяцев
                | . Честно, перепробовал море всего, начиная с мощных десктоп продуктов и заканчивая навороченным самописным приложением в экселе. С VBA, го и гейшами. И в итоге, я пользуюсь ХаниМани уже больше года.
              p
                | Я начал пользоваться системой, потому что в какой-то момент понял, что
                strong  зарабатываю уже в разы больше, чем пару лет до того, а в кармане ничего не остается
                | . Стало очевидно, что нужно оптимизировать процесс трат и заработков. Пару месяцев я копил статистику, потом ввел планы на расходы и достаточно жесткое, хотя и реальное бюджетирование. Спустя еще буквально пару месяцев я почувствовал результат. Поэтому и пользуюсь системой до сих пор.
              p
                | Чем она хороша — она проста и наглядна. Почти все вещи очевидны. Почти все делается в пару щелчков мышкой. Почти всегда понятно, сколько у тебя свободных денег и сколько нужно потратить в ближайшем будущем. Всегда можно просто поменять фактическое значение денег на счете, без нудных расчетов поправок. И, главное,
                strong  система работает — я стал больше экономить, мне стали понятны мои фактические потребности.
            .testimonial-arrow-down
            p.testimonial-author
              | Олег
              span  (Санкт-Петербург)

</template>

<style lang='sass'>
.pricing-page
  .price_table
    .subtitle
      margin-bottom: 0
  .doing, .not_doing, .you_get
    text-align: center
    h3
      margin-bottom: 0
  h2
    margin-bottom: 5px
  h1
    margin-bottom: 0.25em
  h1, h2, h3, h4, h5
    font-weight: 400
  .message-body
    // border-width: 1px
</style>
