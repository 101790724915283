






export default
  name: 'hm_slideshow'
  mounted: ->
    $el = $(@$el)
    $el.find(".hm_slideshow > div:gt(0)").hide()

    f = =>
      $el.find('.hm_slideshow > div:first')
        .fadeOut(1000)
        .next()
        .fadeIn(1000)
        .end()
        .appendTo($el.find('.hm_slideshow'))
    setInterval f, 5000
